import { createEntityAdapter, createSlice, EntityState, PayloadAction, Update } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { FinancialReportType } from 'models/enums/financialReportType';
import { selectedTagsUpdated } from '../Grid/actions';
import { constraintsUpdated } from '../Grid/components/grantConstraintSlice';

export type YearFilterState = { id: string; selected: boolean }

export interface FilterState {
    selectedTags: string[],
    selectedYears: EntityState<YearFilterState>
    showBudgetsOnly: boolean;
    hasTagFiltersApplied: boolean;
    hasYearFiltersApplied: boolean;
}

const yearsAdapter = createEntityAdapter<YearFilterState>({
    selectId: (year) => year.id
})

const initialState: FilterState = {
    selectedTags: [],
    selectedYears: yearsAdapter.getInitialState(),
    showBudgetsOnly: false,
    hasTagFiltersApplied: false,
    hasYearFiltersApplied: false
}

export const filterSlice = createSlice({
    name: "filters",
    initialState,
    reducers: {
        setSelectedYears: (state, action: PayloadAction<YearFilterState[]>) => {
            yearsAdapter.setAll(state.selectedYears, action.payload);
        },
        setSelectedYear: (state, action: PayloadAction<Update<YearFilterState>>) => {
            yearsAdapter.updateOne(state.selectedYears, action.payload);
        },
        setShowBudgetsOnly: (state, action: PayloadAction<boolean>) => {
            state.showBudgetsOnly = action.payload;

        },
        setHasYearFiltersApplied: (state, action: PayloadAction<boolean>) => {
            state.hasYearFiltersApplied = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(selectedTagsUpdated, (state, action) => {
            state.selectedTags = action.payload;
            state.hasTagFiltersApplied = state.selectedTags.length > 0 || selectYearFilters(state).some(year => !year.selected);
        })
        builder.addCase(constraintsUpdated, (state, action) => {
            const { financialReportYearId, financialReportType } = action.payload;
            if(financialReportYearId) {
                const yearFilterUpdates = selectYearFilters(state)
                    .map(year => ({
                        id: year.id,
                        changes: {
                            selected: year.id === financialReportYearId && financialReportType !== FinancialReportType.Single
                        }
                    }))
                yearsAdapter.updateMany(state.selectedYears, yearFilterUpdates);
            }
        })
    }
});

export const { setSelectedYears, setSelectedYear, setShowBudgetsOnly, setHasYearFiltersApplied } = filterSlice.actions;
export const filterState = (state: RootState) => state.filters;
export const { selectAll: selectYearFilters, selectById: selectYearFilterbyId } = yearsAdapter.getSelectors((state: FilterState) => state.selectedYears)
export const selectedYears = (state: FilterState) => selectYearFilters(state).filter(year => year.selected).map(year => year.id);
export const selectedFilterTags = (state: RootState) => state.filters.selectedTags;
export default filterSlice.reducer;
