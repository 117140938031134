import {createEntityAdapter, createSlice, EntityState, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'app/store';
import {BudgetType, Year, YearStatus} from "services/api/responseModels/budgetPeriodResponseModel";
import {setSelectedYear} from '../../Filter/filterSlice';
import {constraintsUpdated, showSingleFinancialReportColumn} from './grantConstraintSlice';
import {budgetSubmitted} from "pages/dashboard/components/Grid/actions";
import {Action} from 'models/enums/action';
import {FinancialReportType} from 'models/enums/financialReportType';
import {BudgetPeriodVersionModel} from "../../../../../services/api/responseModels/budgetPeriodVersionModel";
import {Status} from "../../../../../models/enums/status";
import {UserModel} from "../../../../../models/userModel";
import {IsSalaryCategory} from "../../../../../infrastructure/extensions/categoryNameAsserter";

export type YearState = {
    shown: boolean;
    entity: Year,
    columns: YearColumn[]
}

export interface CollapseState {
    budgetCollapsed: boolean;
    descriptionCollapsed: boolean;
    differenceCollapsed?: boolean;
    fulltimeEquivalentCollapsed?: boolean;
}

export interface YearColumn extends CollapseState {
    type: BudgetType,
    locked: boolean,
    showDifferenceColumn: boolean,
    showFulltimeEquivalentColumn: boolean
}

export enum ColumnTarget {
    description = 1,
    budget = 2,
    difference = 3,
    fulltimeEquivalent = 4,
}

const yearAdapter = createEntityAdapter<YearState>({
    selectId: (year) => year.entity.id
})

export const yearSlice = createSlice({
    name: "years",
    initialState: yearAdapter.getInitialState(),
    reducers: {
        setYears: (state, action: PayloadAction<({years: Year[], columnTypes: BudgetType[], isReadonly: boolean, closedFinancialYears: number[], actionEnum: Action, financialReportType: FinancialReportType, financialReportYearId?: string, isCurrentVersion?: boolean, selectedVersion: BudgetPeriodVersionModel})>) => {
            const { years, columnTypes, isReadonly, closedFinancialYears, actionEnum, financialReportType, financialReportYearId, isCurrentVersion, selectedVersion } = action.payload;
            const hideYears = financialReportType === FinancialReportType.Single && actionEnum === Action.FinancialReport;
            const yearStates = years.map(year => {
                const yearState = {
                    shown: !hideYears,
                    entity: year,
                    columns: columnTypes
                        .filter(type => filterTypeForYear(year, type, year.yearNumber, year.id, closedFinancialYears, actionEnum, financialReportType, financialReportYearId, isCurrentVersion, selectedVersion))
                        .map(type => {
                            const shownColumnTypesForYear = columnTypes.filter(type => filterTypeForYear(year, type, year.yearNumber, year.id, closedFinancialYears, actionEnum, financialReportType, financialReportYearId, isCurrentVersion, selectedVersion));
                            const isBudgetColumnLocked = type === BudgetType.budget && actionEnum !== Action.CreateBudget;
                            const isRevisionColumnLocked = type === BudgetType.revision && actionEnum !== Action.BudgetRevision;
                            const shouldShowDifferenceColumn = showDifferenceColumn(type, shownColumnTypesForYear);
                            const shouldShowFulltimeEquivalentColumn = showFteColumn(type);
                            return {
                                type: type,
                                locked: isReadonly || year.status === YearStatus.closed || isBudgetColumnLocked || isRevisionColumnLocked,
                                showDifferenceColumn: shouldShowDifferenceColumn,
                                showFulltimeEquivalentColumn: shouldShowFulltimeEquivalentColumn,
                                budgetCollapsed: false,
                                descriptionCollapsed: false,
                                differenceCollapsed: shouldShowDifferenceColumn ? false : undefined,
                                fulltimeEquivalentCollapsed: shouldShowFulltimeEquivalentColumn ? false : undefined
                            }
                        })
                };

                return yearState;
            })

            yearAdapter.setAll(state, yearStates);
        },
        collapseStateUpdated: (state, action: PayloadAction<({ yearId: string, type: BudgetType, columnTarget: ColumnTarget, collapsed: boolean })>) => {
            const { yearId, type, collapsed, columnTarget } = action.payload;
            const year = selectYearById(state, yearId) as YearState;
            const column = year.columns.find(c => c.type === type) as YearColumn;

            const columnsCopy = year.columns.slice();

            switch (columnTarget) {
                case ColumnTarget.budget:
                    columnsCopy[columnsCopy.indexOf(column)] = { ...column, budgetCollapsed: collapsed };
                    break;
                case ColumnTarget.difference:
                    columnsCopy[columnsCopy.indexOf(column)] = { ...column, differenceCollapsed: collapsed };
                    break;
                case ColumnTarget.description:
                    columnsCopy[columnsCopy.indexOf(column)] = { ...column, descriptionCollapsed: collapsed };
                    break;
                case ColumnTarget.fulltimeEquivalent:
                    columnsCopy[columnsCopy.indexOf(column)] = { ...column, fulltimeEquivalentCollapsed: collapsed };
                    break;
            }

            let yearShown = year.shown;

            columnsCopy.map((column) => {
                let dynamicColumnsCollapsed = false;

                if (column.showFulltimeEquivalentColumn && column.fulltimeEquivalentCollapsed) {
                    dynamicColumnsCollapsed = true;
                }

                if (column.showDifferenceColumn && column.differenceCollapsed) {
                    dynamicColumnsCollapsed = true;
                }

                if (column.descriptionCollapsed && column.budgetCollapsed && dynamicColumnsCollapsed) {
                    yearShown = false;
                }
            })

            yearAdapter.updateOne(state, { id: yearId, changes: { shown: yearShown, columns: columnsCopy }})
        }
    },
    extraReducers: (builder) => {
        builder.addCase(setSelectedYear, (state, action) => {
            const { id, changes } = action.payload;
            yearAdapter.updateOne(state, { id: id, changes: { shown: changes.selected } });
        })
        builder.addCase(constraintsUpdated, (state, action) => {
            const { financialReportYearId, financialReportType } = action.payload;

            if(financialReportType === FinancialReportType.Single) return;

            if(financialReportYearId) {
                const yearFilterUpdates = selectAllYears(state).map(year => ({ id: year.entity.id, changes: { shown: year.entity.id === financialReportYearId }}));
                yearAdapter.updateMany(state, yearFilterUpdates);
            }
        })
        builder.addCase(budgetSubmitted, (state, action) => {
            const yearsWithColumns = selectAllYears(state);

            const updates = yearsWithColumns.map(year => {
                const columnsCopy = year.columns.map(c => { return { ...c, locked: true}});
                return ({ id: year.entity.id, changes: {columns: columnsCopy}});
            });

            yearAdapter.updateMany(state, updates);
        })
    }
});

export const { setYears, collapseStateUpdated } = yearSlice.actions;
export const yearState = (state: RootState) => state.years;
export default yearSlice.reducer;
export const { selectAll: selectAllYears } = yearAdapter.getSelectors();
export const { selectById: selectYearById } = yearAdapter.getSelectors();
export const selectShownYears = (state: EntityState<YearState>) => selectAllYears(state).filter(year => year.shown);

export const filterTypeForYear = (year: Year, type: BudgetType, yearNumber: number, yearId: string, closedFinancialYears: number[], actionEnum: Action, financialReportType: FinancialReportType, financialReportYear?: string, isCurrentVersion: boolean = true, selectedVersion?: BudgetPeriodVersionModel): boolean => {

    if (year.columnsDataTypes.includes(type)) return true;

    return false;
}

export const singleFinancialReportColumns = 3;
export const selectAmountOfColumnsShown = (state: EntityState<YearState>, showTotal: boolean, showSingleFinancialReportColumn: boolean) => {
    const totalColumns = selectShownYears(state)
    .flatMap(y => y.columns)
    .reduce((a, column) => {
        const columnsForType = column.showDifferenceColumn || column.showFulltimeEquivalentColumn ? 3 : 2;
        return columnsForType + a;
    }, 0);

    const institutionColumn = 1;
    const sumColumn = showTotal ? 1 : 0;
    const reportColumn = showSingleFinancialReportColumn ? singleFinancialReportColumns : 0;
    return totalColumns + institutionColumn + sumColumn + reportColumn;
}

export const selectColumnsShown = (state: EntityState<YearState>) =>
selectShownYears(state)
.flatMap(y => y.columns);

export const selectNavigatableColumns = (state: RootState) => {
    const showSingleFinancialReport = showSingleFinancialReportColumn(state);
    let navigatableColumns = selectColumnsShown(state.years)
        .filter(c => !c.locked)
        .map(c => {
            let columns = 0;

            if(!c.fulltimeEquivalentCollapsed &&
                c.showFulltimeEquivalentColumn)
                columns++;

            if(!c.budgetCollapsed)
                columns++;

            if(!c.descriptionCollapsed)
                columns++;

            return columns;
        })
        .reduce((a, b) => a + b, 0)

    if(showSingleFinancialReport) navigatableColumns = navigatableColumns + 2;

    return navigatableColumns;
}

export const selectColumnTypesForYear = (state: EntityState<YearState>, yearId: string) => {
    const year = selectYearById(state, yearId) as YearState;

    return year.columns.map(column => column.type).filter((x, i, a) => a.indexOf(x) === i)
};

export const selectSingleFinancialReportYearId = (state: RootState) => {
    const sortedYears = [...selectAllYears(state.years)].sort((a, b) => a.entity.yearNumber > b.entity.yearNumber ? -1 : 1).map(y => y.entity.id);
    return sortedYears[0];
};

export type YearColumns = {
    yearId: string;
    columns: BudgetType[];
}
export const selectAllColumnTypes = (state: EntityState<YearState>): YearColumns[] => {
    const years = selectAllYears(state);

    return years.map(y => ({
        yearId: y.entity.id,
        columns: y.columns.map(column => column.type).filter((x, i, a) => a.indexOf(x) === i)
    }));
};

export const showDifferenceColumn = (type: BudgetType, columnTypes: BudgetType[]) => {
    return (type === BudgetType.revision && columnTypes.includes(BudgetType.budget)) || (type === BudgetType.financial && columnTypes.length > 1);
};

    const showFteColumn = (type: BudgetType) =>
        type === BudgetType.budget ||
        type === BudgetType.revision ||
        type === BudgetType.financial;

    export const selectCollapseStates = (state: EntityState<YearState>, yearId: string, type: BudgetType, target: ColumnTarget): boolean => {
    const year = selectYearById(state, yearId) as YearState;
    const column = year.columns.find(c => c.type === type) as YearColumn;

    switch (target) {
        case ColumnTarget.budget:
            return column.budgetCollapsed;
        case ColumnTarget.description:
            return column.descriptionCollapsed;
        case ColumnTarget.difference:
            return column.differenceCollapsed as boolean;
        case ColumnTarget.fulltimeEquivalent:
            return column.fulltimeEquivalentCollapsed as boolean;
        default:
            throw new Error("Unknown column target: " + target);
    }
}

export const selectCollapseState = (state: EntityState<YearState>, yearId: string, type: BudgetType, target: ColumnTarget): boolean => {
    const year = selectYearById(state, yearId) as YearState;
    const column = year.columns.find(c => c.type === type) as YearColumn;

    switch (target) {
        case ColumnTarget.budget:
            return column.budgetCollapsed;
        case ColumnTarget.description:
            return column.descriptionCollapsed;
        case ColumnTarget.difference:
            return column.differenceCollapsed as boolean;
        case ColumnTarget.fulltimeEquivalent:
            return column.fulltimeEquivalentCollapsed as boolean;
        default:
            throw new Error("Unknown column target: " + target);
    }
}

export const getYearsToDelete = (state: RootState) : string[] => {
    return selectAllYears(state.years).filter(y => y.entity.removed).map(y => y.entity.id);
}
