import {Redirect, Route} from 'react-router-dom';
import { Routes } from './routes';
import { useAuth } from './auth';
import {PageWrapper} from "../../layout/pageWrapper/pageWrapper";

export function PrivateRoute({children, ...rest}: {[p: string]: any, children: any}): JSX.Element {
    const { user, isLoadingUser } = useAuth();
    const authenticated = user && user.authenticated;

    if(isLoadingUser) return <></>;

    return (
        <Route
            {...rest}
            render={({ location }) =>
                authenticated ? (
                    <PageWrapper children={children}/>
                ) : (
                    <Redirect
                        to={{
                            pathname: Routes.LoggedOut.path,
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}
