import {useEffect, useState} from 'react';
import { Redirect, useLocation } from "react-router-dom";
import {Routes} from "infrastructure/routing/routes";
import { useAuth } from 'infrastructure/routing/auth';
import { StorageKeys } from 'infrastructure/storageKeys';

interface LocationState {
    from: {
         pathname: string,
         search: string
    }
};

export const AuthPage = () => {
    const location = useLocation<LocationState>();
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const hasToken = token ? true : false;
    const [loginFailed, setLoginFailed] = useState(!hasToken);
    const [loginDone, setLoginDone] = useState(false);
    localStorage.setItem(StorageKeys.token, token as string);
    const auth = useAuth();

    useEffect(() => {
        async function login() {
            const success = await auth.signInToken(token as string);

            if(!success) {
                setLoginFailed(true);
            } else {
                setLoginDone(true);
            }
        }
        login();

        return () => {}
     }, [auth, token])

    return loginDone ?
        <Redirect to={Routes.Index.path} /> :
        (<div className="d-flex justify-content-center align-items-center">
            {loginFailed ? <h3>Unauthorized</h3> : <h3>Logging in...</h3>}
        </div>);
}
