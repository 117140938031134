import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { DashboardPage} from "pages/dashboard/dashboard";
import { PrivateRoute } from "./privateRoute";
import { Routes } from "./routes";
import { ErrorBoundary } from "infrastructure/errors/errorBoundary";
import { LogoutPage } from "pages/logoutPage/logoutPage";
import { AuthPage } from "pages/authPage/authPage";
import { NotFoundPage } from "infrastructure/errors/notFoundPage";
import { ProvideAuth } from "./auth";

export default function MainRouting() {
    return (
        <Router>
            <ProvideAuth>
                <ErrorBoundary>
                    <Switch>
                        <PrivateRoute path={Routes.Index.path} exact>
                            <DashboardPage/>
                        </PrivateRoute>

                        <Route path={Routes.Auth.path} exact>
                            <AuthPage />
                        </Route>

                        <Route path={Routes.LoggedOut.path} exact>
                            <LogoutPage />
                        </Route>

                        <Route path="*">
                            <NotFoundPage />
                        </Route>
                    </Switch>
                </ErrorBoundary>
            </ProvideAuth>
        </Router>
    );
}
