import { RootState } from "app/store";
import { FinancialReportType } from "models/enums/financialReportType";
import { useSelector } from "react-redux";
import { BudgetType, Subcategory, ValueResponseModel } from "services/api/responseModels/budgetPeriodResponseModel";
import { calculateSumOfSubcategories, selectAllCells } from "./cellSlice";
import {
	GrantConstraints,
	selectGrantConstraints,
	showSingleFinancialReportColumn,
} from "./grantConstraintSlice";
import { selectAllYears, selectShownYears, selectSingleFinancialReportYearId, YearState } from "./yearSlice";
import React, { useMemo } from "react";
import { formatWithThousandSeperators } from "../../../../../infrastructure/extensions/numberFormatter";
import { getSumProjectSupplementPerYear } from "./projectSupplement";

interface IComparerCellProps {
	currency: string;
	subcategories: Subcategory[];
	isTotalRow?: boolean;
}

export function SumCell(props: IComparerCellProps) {
	const { budgetType, isReadonly, financialReportType } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;
	const showSingleFinancialReport = useSelector<RootState>(state => showSingleFinancialReportColumn(state)) as boolean;

	const columnTypes = financialReportType === FinancialReportType.Single ? [budgetType] : [budgetType, BudgetType.financial];

	const yearsToCalculateFrom = useSelector<RootState>(state => {
		if (showSingleFinancialReport) {
      var allYears = selectAllYears(state.years);
			return allYears.filter(y => !y.entity.removed);
		} else {
			return selectShownYears(state.years).filter(y => !y.entity.removed);
		}
	}) as YearState[];

  const allSumProjectSupplementPerYear = React.useMemo(
    () =>
      yearsToCalculateFrom.flatMap(year =>
        year.columns.flatMap(column => ({
          yearId: year.entity.id,
          type: column.type,
          sum: getSumProjectSupplementPerYear(
            year.entity.id,
            column.type,
            props.subcategories
          )
        }))
      ),
    [yearsToCalculateFrom, props.subcategories]
  );

  const totalProjectSupplement = React.useMemo(
    () =>
      allSumProjectSupplementPerYear
        .filter(ps => ps.type === budgetType)
        .map(sum => sum.sum)
        .reduce((a, b) => a + b, 0),
    [allSumProjectSupplementPerYear, budgetType]
  );

	const { sum } = useSelector<RootState>(state => calculateSumOfSubcategories(state, columnTypes, props.subcategories, yearsToCalculateFrom.map(y => y.entity.id))) as { sum: number | undefined, formattedSum: string | undefined };
	const total = useMemo(() => props.isTotalRow ? (sum ?? 0) + totalProjectSupplement : (sum ?? 0), [props.isTotalRow, sum, totalProjectSupplement])


	return (
		<div className={`category-row-cell-content content-align-right padding-right-m padding-xs sum-cell ${isReadonly && "readonly"}`}>
			{formatWithThousandSeperators(total)} {props.currency}
		</div>
	);
}
export default SumCell;
