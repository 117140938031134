import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
  Update,
} from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  IsNotAdministrativeSubcategory,
  IsSalaryCategory,
} from "infrastructure/extensions/categoryNameAsserter";
import { formatWithThousandSeperators } from "infrastructure/extensions/numberFormatter";
import {
  BudgetType,
  Subcategory,
  ValueResponseModel,
  YearStatus,
} from "services/api/responseModels/budgetPeriodResponseModel";
import { subcategoriesAdded } from "./subcategorySlice";
import { Action } from "models/enums/action";
import { groupedCategories } from "../grid";
import { selectAllYears, YearState } from "./yearSlice";
import { subcategoryRemoved } from "../actions";

const cellAdapter = createEntityAdapter<ValueResponseModel>({
  selectId: (value) => `${value.subcategoryId}-${value.yearId}-${value.type}`,
});
const fteValueLimit = 100;

export const cellSlice = createSlice({
  name: "cells",
  initialState: cellAdapter.getInitialState(),
  reducers: {
    addCells: (state, action: PayloadAction<ValueResponseModel[]>) => {
      cellAdapter.removeAll(state);
      cellAdapter.addMany(state, action.payload);
    },
    updateCell: (
      state,
      action: PayloadAction<{
        update: Update<ValueResponseModel>;
        maxSubcategoryDeviation: number;
        yearId: string;
        subcategoryId: string;
        shownSubcategories: Subcategory[];
        cellTypes: BudgetType[];
        showDifferenceCell: boolean;
      }>
    ) => {
      const {
        update,
        maxSubcategoryDeviation,
        yearId,
        cellTypes,
        subcategoryId,
        shownSubcategories,
        showDifferenceCell,
      } = action.payload;
      const currentCell = selectById(state, update.id) as ValueResponseModel;
      const cellsInCategory = selectCellsInCategory(state, [
        subcategoryId,
      ]) as ValueResponseModel[];
      const comparerType = findComparerType(currentCell.type, cellTypes);

      const copiedCells = cellsInCategory.slice();

      if (update.changes.budget !== undefined)
        copiedCells[cellsInCategory.indexOf(currentCell)] = {
          ...currentCell,
          budget: update.changes.budget,
        };

      if (update.changes.description !== undefined)
        copiedCells[cellsInCategory.indexOf(currentCell)] = {
          ...currentCell,
          description: update.changes.description,
        };

      const { differenceInPercent } = differenceBetweenYears(
        yearId,
        currentCell.type,
        comparerType,
        copiedCells,
        shownSubcategories
      );
      if (differenceInPercent) {
        if (
          currentCell.type === BudgetType.financial &&
          differenceInPercent !== 0
        ) {
          update.changes.hasIssue = true;
        } else if (maxSubcategoryDeviation) {
          const hasIssue = isMaxDeviationExceeded(
            differenceInPercent,
            maxSubcategoryDeviation,
            showDifferenceCell
          );
          update.changes.hasIssue = hasIssue;
        } else update.changes.hasIssue = false;
      } else update.changes.hasIssue = false;

      cellAdapter.updateOne(state, update);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(subcategoriesAdded, (state, action) => {
      const cellsToAdd = action.payload.subcategories.flatMap((subcategory) => {
        return action.payload.years.flatMap((year) => {
          return year.columns.map<ValueResponseModel>((column) => ({
            subcategoryId: subcategory.id,
            yearId: year.entity.id,
            type: column.type,
            budget: undefined,
            description: undefined,
            fullTimeEquivalent: undefined,
            commentRequired: false,
            fulltimeEquivalentRequired: IsSalaryCategory(
              subcategory.category.name
            )
              ? false
              : undefined,
            tabIndex: undefined,
            yearNumber: year.entity.yearNumber,
            categoryNumber: action.payload.categoryNumber,
            createdAt: Number(new Date()),
            hasIssue: false,
            institutionId: undefined,
            projectSupplementAmount: undefined,
          }));
        });
      });

      cellAdapter.addMany(state, cellsToAdd);
    });
    builder.addCase(subcategoryRemoved, (state, action) => {
      const cellsToRemove = selectCellsInCategory(state, [action.payload]);
      cellAdapter.removeMany(
        state,
        cellsToRemove.map(
          (value) => `${value.subcategoryId}-${value.yearId}-${value.type}`
        )
      );
    });
  },
});

export const { addCells, updateCell } = cellSlice.actions;
export const cellState = (state: RootState) => state.cells;
export default cellSlice.reducer;

const { selectById, selectAll } = cellAdapter.getSelectors();
export const selectAllCells = selectAll;
export const selectCellById = (
  state: RootState,
  subcategoryId: string,
  yearId: string,
  type: BudgetType
) => selectById(state.cells, `${subcategoryId}-${yearId}-${type}`);

export const selectCellsInCategory = (
  state: EntityState<ValueResponseModel>,
  subcategories: string[]
) =>
  selectAllCells(state).filter((c) => subcategories.includes(c.subcategoryId));

export const calculateSumOfSubcategories = (
  state: RootState,
  typesToInclude: BudgetType[],
  shownSubcategories: Subcategory[],
  shownYearIds: string[]
): { sum: number | undefined; formattedSum: string | undefined } => {
  const subcategoryIds = shownSubcategories.map((sub) => sub.id);
  const cells = selectCellsInCategory(state.cells, subcategoryIds);

  var cellsWithValues = cells
    .filter(
      (c) =>
        typesToInclude.includes(c.type) &&
        subcategoryIds.includes(c.subcategoryId) && // only include cells in provided subcategories (typically only one or all shown subcategories if we're calculating total)
        shownYearIds.includes(c.yearId) && // only include cells shown in currenctly selected years
        c.budget !== undefined
    )
    .map((c) => c.budget as number);

  if (cellsWithValues.length === 0)
    return { sum: undefined, formattedSum: undefined };

  const sum = cellsWithValues.reduce((total, value) => total + value, 0);

  const formattedSum = formatWithThousandSeperators(sum);
  return { sum, formattedSum };
};

export const calculateSumOfYear = (
  yearId: string,
  budgetType: BudgetType,
  cells: ValueResponseModel[],
  shownSubcategories: Subcategory[]
) => {
  const subcategoryIds = shownSubcategories.map((sub) => sub.id);
  const cellsWithValues = cells
    .filter(
      (c) =>
        c.yearId === yearId &&
        c.type === budgetType &&
        subcategoryIds.includes(c.subcategoryId) &&
        c.budget !== undefined
    )
    .map((c) => c.budget as number);

  if (cellsWithValues.length === 0) return;

  const sum = cellsWithValues.reduce((total, value) => total + value, 0);

  return sum;
};

export const calculateSumOfBudget = (
  budgetType: BudgetType,
  cells: ValueResponseModel[],
  shownSubcategories: Subcategory[]
) => {
  const subcategoryIds = shownSubcategories.map((sub) => sub.id);
  const cellsWithValues = cells
    .filter(
      (c) =>
        c.type === budgetType &&
        subcategoryIds.includes(c.subcategoryId) &&
        c.budget !== undefined
    )
    .map((c) => c.budget as number);

  if (cellsWithValues.length === 0) return;

  const sum = cellsWithValues.reduce((total, value) => total + value, 0);

  return sum;
};

export const findComparerType = (
  currentType: BudgetType,
  columnTypes: BudgetType[]
) => columnTypes.find((c) => c !== currentType) as BudgetType;
export const isMaxDeviationExceeded = (
  actualDifference: number,
  constraint: number,
  showDifferenceCell: boolean
) => Math.abs(actualDifference) > constraint && showDifferenceCell;
export const isDescriptionRequired = (
  budget: number | undefined,
  description: string | undefined,
  subcategory: Subcategory
) =>
  IsNotAdministrativeSubcategory(subcategory.name) &&
  budget !== undefined &&
  budget !== null &&
  budget > 0 &&
  !description;

export const singleFinancialReportDifference = (
  state: RootState,
  typesToInclude: BudgetType[],
  shownSubcategories: Subcategory[],
  shownYearIds: string[],
  financialReportYearId: string
): {
  difference: string;
  differenceInPercent: number | undefined;
  differenceInPercentFormatted: string;
} => {
  const subcategoryIds = shownSubcategories.map((sub) => sub.id);
  const cells = selectCellsInCategory(state.cells, subcategoryIds);

  const sumOfSingleFinancialReport =
    calculateSumOfYear(
      financialReportYearId,
      BudgetType.financial,
      cells,
      shownSubcategories
    ) ?? 0;
  const totalSum =
    calculateSumOfSubcategories(
      state,
      typesToInclude,
      shownSubcategories,
      shownYearIds
    )?.sum ?? 0;

  const difference = sumOfSingleFinancialReport - totalSum;
  const percentDifference = (difference / totalSum) * 100;

  return {
    difference: formatWithThousandSeperators(difference),
    differenceInPercent: percentDifference,
    differenceInPercentFormatted: `${
      Math.sign(percentDifference) > 0 ? "+" : ""
    }${formatWithThousandSeperators(Math.round(percentDifference))}`,
  };
};

export const sumOfYear = (
  yearId: string,
  budgetType: BudgetType,
  cells: ValueResponseModel[],
  shownSubcategories: Subcategory[]
) => {
  const sum = calculateSumOfYear(yearId, budgetType, cells, shownSubcategories);

  if (!sum) return;

  return formatWithThousandSeperators(sum);
};

export const sumOfYearInt = (
  yearId: string,
  budgetType: BudgetType,
  cells: ValueResponseModel[],
  shownSubcategories: Subcategory[]
) => {
  const sum = calculateSumOfYear(yearId, budgetType, cells, shownSubcategories);

  if (!sum) return;

  return sum;
};

export const differenceBetweenYears = (
  yearId: string,
  currentColType: BudgetType,
  compareWithType: BudgetType,
  cells: ValueResponseModel[],
  shownSubcategories: Subcategory[]
): {
  difference: string;
  differenceInPercent: number | undefined;
  differenceInPercentFormatted: string;
} => {
  const sumOfCurrent =
    calculateSumOfYear(yearId, currentColType, cells, shownSubcategories) ?? 0;
  const sumOfComparer =
    calculateSumOfYear(yearId, compareWithType, cells, shownSubcategories) ?? 0;

  const difference = sumOfCurrent - sumOfComparer;
  const percentDifference = (difference / sumOfComparer) * 100;

  return {
    difference: formatWithThousandSeperators(difference),
    differenceInPercent: percentDifference,
    differenceInPercentFormatted: `${
      Math.sign(percentDifference) > 0 ? "+" : ""
    }${formatWithThousandSeperators(Math.round(percentDifference))}`,
  };
};

export const selectBudgetTotal = (
  state: RootState,
  isCurrentVersion: boolean
) => {
  const removedYears = selectAllYears(state.years)
    .filter((y) => y.entity.removed)
    .map((y) => y.entity.id);
  const closedYearIds = selectAllYears(state.years)
    .filter((t) => t.entity.status === YearStatus.closed)
    .map((y) => y.entity.id);

  return selectAllCells(state.cells)
    .filter(
      (c) =>
        !removedYears.includes(c.yearId) &&
        ((!closedYearIds.includes(c.yearId) &&
          c.type === state.grantConstraints.budgetType) ||
          (closedYearIds.includes(c.yearId) &&
            c.type === BudgetType.financial)) && // for closed years cell should be type Financial
        c.budget !== undefined
    )
    .map((c) => c.budget as number)
    .reduce((total, budget) => total + budget, 0);
};

export const anyCellsWithRequired = (state: RootState, action: Action) => {
  if (action === Action.View) return false;
  return (
    selectAllCells(state.cells).filter(
      (t) =>
        (t.budget !== undefined && t.budget > 0 && t.commentRequired) ||
        (action === Action.CreateBudget && t.fulltimeEquivalentRequired)
    ).length > 0
  );
};

export const anyCellsWithFteAbove100 = (state: RootState, action: Action) => {
  if (action === Action.View) return false;
  return (
    selectAllCells(state.cells).filter(
      (t) =>
        t.fullTimeEquivalent !== undefined &&
        t.fullTimeEquivalent >= fteValueLimit &&
        action === Action.CreateBudget
    ).length > 0
  );
};

export const anyCellsWithIssues = (state: RootState) =>
  selectAll(state.cells).filter((c) => c.hasIssue).length > 0;

export const anyCategoriesWithIssues = (
  state: RootState,
  allSubcategories: Subcategory[],
  allYears: YearState[],
  maxCategoryDeviation: number
) => {
  const allCells = selectAllCells(state.cells);
  const categories = groupedCategories(allSubcategories);
  let hasIssue = false;

  allYears.forEach((year) => {
    const columnTypes = year.columns.map((c) => c.type);

    year.columns.forEach((column) => {
      if (!column.showDifferenceColumn) return;

      const comparerType = findComparerType(column.type, columnTypes);

      categories.forEach((category) => {
        const { differenceInPercent } = differenceBetweenYears(
          year.entity.id,
          column.type,
          comparerType,
          allCells,
          category.subcategories
        );
        if (
          differenceInPercent &&
          Math.abs(differenceInPercent) > maxCategoryDeviation
        ) {
          hasIssue = true;
        }
      });
    });
  });

  return hasIssue;
};

