import { CellType } from "models/enums/cellType";
import { useAppDispatch } from "app/hooks";
import { useEffect, useRef } from "react";
import { selectCellById, updateCell, isDescriptionRequired } from "./cellSlice";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { BudgetType, Subcategory, ValueResponseModel } from "services/api/responseModels/budgetPeriodResponseModel";
import Cell from "./cell";
import { useUpdateValueMutation } from "services/api/budgetPeriodApi";
import { ValueRequestModel } from "services/api/requestModels/valueRequestModel";
import { parseToNumber, formatWithThousandSeperators } from "infrastructure/extensions/numberFormatter";
import ComparerCell from "./comparerCell";
import CellContainer from "./cellContainer";
import { CollapseState, selectColumnTypesForYear } from "./yearSlice";
import { GrantConstraints, selectGrantConstraints } from "./grantConstraintSlice";
import Icon from "core/icon/icon";
import { Localizer } from "infrastructure/localization/localizer";
import { Tooltip } from "core/tooltip/tooltip";
import { updateBudgetOnSubcategoryValue, updateDescriptionOnSubcategoryValue, updateProjectSupplementOnSubcategoryValue } from "./subcategorySlice";

interface ICellYearWrapperProps extends CollapseState {
	columnIndex: number;
	verticalIndex: number;
	subcategory: Subcategory;
	subcategoryId: string;
	yearId: string;
	currency: string;
	type: BudgetType;
	isReadonly: boolean;
	showDifferenceCell: boolean;
	showFulltimeEquivalentCell: boolean;
	canEnterFulltimeEquivalent: boolean;
}

export function CellYearWrapper(props: ICellYearWrapperProps) {
	const [updateValue] = useUpdateValueMutation();
	const dispatch = useAppDispatch();
	const columnTypes = useSelector<RootState>(state => selectColumnTypesForYear(state.years, props.yearId)) as BudgetType[];
	const cell = useSelector<RootState>(state => selectCellById(state, props.subcategoryId, props.yearId, props.type)) as ValueResponseModel;
	const { maxSubcategoryDeviation, budgetType: currentBudgetPeriodType } = useSelector<RootState>(state => selectGrantConstraints(state)) as GrantConstraints;
	const fteValueLimit = 100;

	const renderedColumnCount = useRef(0);

	const updateRenderedColumnCount = () => renderedColumnCount.current = renderedColumnCount.current + 1;

	const handleCellChange = async (update: ValueRequestModel) => {
		try {
			const result = await updateValue(update).unwrap();
			return result;
		} catch (error) {
			console.warn(error);
			return undefined;
		}
	}

	const handleBudgetChange = async (budget: number | undefined): Promise<boolean> => {
		const isSuccess = await handleCellChange({
			budgetPeriodSubCategoryId: props.subcategoryId,
			budgetPeriodYearId: props.yearId,
			type: props.type,
			budget: budget,
			fullTimeEquivalent: cell.fullTimeEquivalent,
			description: cell.description,
		});

		if (!isSuccess) return false;

		const fulltimeEquivalentRequired = shouldHaveRequiredFulltimeEquivalent(isSuccess.budget, cell.fullTimeEquivalent, cell.fulltimeEquivalentRequired);
		const descriptionRequired = isDescriptionRequired(budget, cell.description, props.subcategory);
		dispatch(updateCell({ update: { id: `${props.subcategoryId}-${props.yearId}-${props.type}`, changes: { budget: isSuccess.budget, fullTimeEquivalent: isSuccess.fullTimeEquivalent, description: isSuccess.description, fulltimeEquivalentRequired: fulltimeEquivalentRequired, commentRequired: descriptionRequired } }, maxSubcategoryDeviation: maxSubcategoryDeviation, yearId: props.yearId, subcategoryId: props.subcategoryId, cellTypes: columnTypes, showDifferenceCell: props.showDifferenceCell, shownSubcategories: [props.subcategory] }));
		dispatch(updateBudgetOnSubcategoryValue({ subcategoryId: props.subcategoryId, yearId: props.yearId, type: props.type, budget: isSuccess.budget }));

		return true;
	}

	const handleFulltimeEquivalentChange = async (fulltimeEquivalent: number | undefined): Promise<boolean> => {
		const isSuccess = await handleCellChange({
			budgetPeriodSubCategoryId: props.subcategoryId,
			budgetPeriodYearId: props.yearId,
			type: props.type,
			budget: cell.budget,
			fullTimeEquivalent: fulltimeEquivalent,
			description: cell.description,
		});

		if (!isSuccess) return false;

		const fulltimeEquivalentRequired = shouldHaveRequiredFulltimeEquivalent(cell.budget, fulltimeEquivalent, cell.fulltimeEquivalentRequired);
		dispatch(updateCell({ update: { id: `${props.subcategoryId}-${props.yearId}-${props.type}`, changes: { budget: isSuccess.budget, fullTimeEquivalent: isSuccess.fullTimeEquivalent, description: isSuccess.description, fulltimeEquivalentRequired: fulltimeEquivalentRequired, projectSupplementAmount: isSuccess.projectSupplementAmount } }, maxSubcategoryDeviation: maxSubcategoryDeviation, yearId: props.yearId, subcategoryId: props.subcategoryId, cellTypes: columnTypes, showDifferenceCell: props.showDifferenceCell, shownSubcategories: [props.subcategory] }));
		dispatch(updateProjectSupplementOnSubcategoryValue({ subcategoryId: props.subcategoryId, yearId: props.yearId, type: props.type, projectSupplementAmount: isSuccess.projectSupplementAmount, fullTimeEquivalent: isSuccess.fullTimeEquivalent }));
		return true;
	}

	const handleDescriptionChange = async (description: string): Promise<boolean> => {
		const isSuccess = await handleCellChange({
			budgetPeriodSubCategoryId: props.subcategoryId,
			budgetPeriodYearId: props.yearId,
			type: props.type,
			budget: cell.budget,
			description: description,
			fullTimeEquivalent: cell.fullTimeEquivalent,
		});

		if (!isSuccess) return false;

		const descriptionRequired = isDescriptionRequired(cell.budget, description, props.subcategory);
		dispatch(updateCell({ update: { id: `${props.subcategoryId}-${props.yearId}-${props.type}`, changes: { budget: isSuccess.budget, fullTimeEquivalent: isSuccess.fullTimeEquivalent, description: isSuccess.description, commentRequired: descriptionRequired } }, maxSubcategoryDeviation: maxSubcategoryDeviation, yearId: props.yearId, subcategoryId: props.subcategoryId, cellTypes: columnTypes, showDifferenceCell: props.showDifferenceCell, shownSubcategories: [props.subcategory] }));
		dispatch(updateDescriptionOnSubcategoryValue({ subcategoryId: props.subcategoryId, yearId: props.yearId, type: props.type, description: isSuccess.description }));
		return true;
	}

	const hasNoFteSibling = !props.showDifferenceCell && !props.canEnterFulltimeEquivalent;

	const renderNumberInput = () => {
		renderedColumnCount.current = 0;

		if (!cell || props.budgetCollapsed) return <></>

		updateRenderedColumnCount();

		return <Cell
			type={CellType.Number}
			value={formatWithThousandSeperators(cell.budget)}
			readModeValueFormatter={(value: string) => value ? `${formatWithThousandSeperators(parseToNumber(value))}` : ''}
			onBlurSave={(value: string) => handleBudgetChange(parseToNumber(value))}
			readonly={props.isReadonly}
			columnIndex={props.columnIndex}
			verticalIndex={props.verticalIndex}
			hasNoFteSibling={hasNoFteSibling}
			maxLength={15}
		/>
	}

	const renderFullTimeEquivalentInput = () => {
		if (!props.canEnterFulltimeEquivalent || !cell) return <></>;

		const isCurrentPeriodInitialBudget = currentBudgetPeriodType === BudgetType.budget;
		if (props.fulltimeEquivalentCollapsed) {
			return isCurrentPeriodInitialBudget && cell.fulltimeEquivalentRequired &&
				<Tooltip message={Localizer.required()} placement={"top"} className="description-collapsed-icon">
					<Icon icon="warning" />
				</Tooltip>;
		}

		const columIndex = props.columnIndex + renderedColumnCount.current;
		updateRenderedColumnCount();

		return <Cell
			type={CellType.Fte}
			value={typeof cell.fullTimeEquivalent !== 'undefined' ? formatWithThousandSeperators(cell.fullTimeEquivalent) : ""}
			readModeValueFormatter={(value: string) => value ? formatWithThousandSeperators(parseToNumber(value)) : ""}
			onBlurSave={(value: string) => handleFulltimeEquivalentChange(parseToNumber(value))}
			readonly={props.isReadonly || !props.canEnterFulltimeEquivalent}
			decimalCount={2}
			required={isCurrentPeriodInitialBudget && cell.fulltimeEquivalentRequired}
			requiredFormat={'small'}
			columnIndex={columIndex}
			verticalIndex={props.verticalIndex}
			maxLength={5}
		/>
	}

	const renderDescriptionInput = () => {
		if (!cell) return <></>

		if (props.descriptionCollapsed) {
			if (cell.commentRequired)
				return <Tooltip message={Localizer.required()} className="description-collapsed-icon" placement={"top"}>
					<Icon icon="warning" />
				</Tooltip>;

			return cell.description && cell.description.length > 0 &&
				<Tooltip message={cell.description} className="description-collapsed-icon" placement={"top"}>
					<Icon icon="dialog" />
				</Tooltip>;
		}

		const columIndex = props.columnIndex + renderedColumnCount.current;
		updateRenderedColumnCount();

		return <Cell
			value={cell.description ? cell.description : ""}
			type={CellType.Text}
			onBlurSave={(value: string) => handleDescriptionChange(value)}
			readonly={props.isReadonly}
			required={cell.commentRequired}
			columnIndex={columIndex}
			verticalIndex={props.verticalIndex}
			hasNoFteSibling={hasNoFteSibling}
		/>;
	}

	const renderComparerCell = () => {
		if (props.differenceCollapsed !== undefined && props.differenceCollapsed) return <></>

		return <ComparerCell
			yearId={props.yearId}
			columnType={props.type}
			currency={props.currency}
			readonly={props.isReadonly}
			subcategories={[props.subcategory]}
		/>
	}

	return (
		<>
			<CellContainer explicitReadOnly={props.isReadonly}>
				{renderNumberInput()}
			</CellContainer>

			{props.showFulltimeEquivalentCell &&
				<CellContainer
					explicitReadOnly={(!props.canEnterFulltimeEquivalent || props.isReadonly) && !props.fulltimeEquivalentCollapsed}
					error={cell.fulltimeEquivalentRequired || (cell.fullTimeEquivalent !== undefined && cell.fullTimeEquivalent?.valueOf() >= fteValueLimit)}>
					{renderFullTimeEquivalentInput()}
				</CellContainer>
			}

			{props.showDifferenceCell &&
				<CellContainer noFocus={true} explicitReadOnly={props.isReadonly}>
					{renderComparerCell()}
				</CellContainer>
			}

			<CellContainer explicitReadOnly={props.isReadonly} error={cell.commentRequired && !props.isReadonly}>
				{renderDescriptionInput()}
			</CellContainer>
		</>
	);
}

export default CellYearWrapper;

const shouldHaveRequiredFulltimeEquivalent = (budget: number | undefined, fulltimeEquivalent: number | undefined, fulltimeEquivalentRequired: boolean | undefined) => {
	if (fulltimeEquivalentRequired === undefined) return undefined;

	return budget !== undefined && budget !== null && typeof fulltimeEquivalent === 'undefined';
}
