import { ApiService } from "services/api/apiService";
import { StorageKeys } from "infrastructure/storageKeys";

export class Localizer {
  public static translations: { [key: string]: string } = {};

  public static async Init() {
    let apiService = new ApiService();
    this.translations = await apiService.getLocalizations();
    localStorage.setItem(
      StorageKeys.localizations,
      JSON.stringify(this.translations)
    );
  }

  public static login = (): string => {
    return Localizer.translations["login"] ?? "Log ind";
  };
  public static loginFailed = (): string => {
    return (
      Localizer.translations["loginFailed"] ??
      "Email eller adgangskode er ikke korrekt."
    );
  };
  public static email = (): string => {
    return Localizer.translations["email"] ?? "Email";
  };
  public static emailRequired = (): string => {
    return Localizer.translations["emailRequired"] ?? "E-mail er påkrævet";
  };
  public static emailValid = (): string => {
    return Localizer.translations["emailValid"] ?? "Indtast valid E-mail";
  };
  public static emailPlaceholder = (): string => {
    return Localizer.translations["emailPlaceholder"] ?? "Indtast din email";
  };
  public static password = (): string => {
    return Localizer.translations["password"] ?? "Adgangskode";
  };
  public static passwordRequired = (): string => {
    return (
      Localizer.translations["passwordRequired"] ?? "Adgangskode er påkrævet"
    );
  };
  public static passwordPlaceholder = (): string => {
    return (
      Localizer.translations["passwordPlaceholder"] ?? "Indtast din adgangskode"
    );
  };
  public static user = (): string => {
    return Localizer.translations["user"] ?? "Bruger";
  };
  public static dashboard = (): string => {
    return Localizer.translations["dashboard"] ?? "Dashboard";
  };
  public static profile = (): string => {
    return Localizer.translations["profile"] ?? "Profil";
  };
  public static logout = (): string => {
    return Localizer.translations["logout"] ?? "Log ud";
  };
  public static errorOccured = (): string => {
    return (
      Localizer.translations["errorOccured"] ??
      "An error occured. Please try and refresh the page. If this persists, please contact an administrator"
    );
  };
  public static errorOccuredTitle = (): string => {
    return Localizer.translations["errorOccuredTitle"] ?? "An error occured";
  };
  public static notFound = (): string => {
    return Localizer.translations["notFound"] ?? "404 - Siden blev ikke fundet";
  };
  public static loggedOut = (): string => {
    return Localizer.translations["loggedOut"] ?? "Logged out";
  };
  public static loginUsingNorma = (): string => {
    return (
      Localizer.translations["loginUsingNorma"] ?? "Please login using Norma"
    );
  };
  public static projects = (): string => {
    return Localizer.translations["projects"] ?? "Projects";
  };
  public static total = (): string => {
    return Localizer.translations["total"] ?? "Total";
  };
  public static draft = (): string => {
    return Localizer.translations["draft"] ?? "In draft";
  };
  public static submitted = (): string => {
    return Localizer.translations["submitted"] ?? "Submitted";
  };
  public static approved = (): string => {
    return Localizer.translations["approved"] ?? "Approved";
  };
  public static chooseCustomCategories = (): string => {
    return Localizer.translations["chooseCustomCategories"] ?? "Filter by tags";
  };
  public static year = (): string => {
    return Localizer.translations["year"] ?? "Year";
  };
  public static budget = (): string => {
    return Localizer.translations["budget"] ?? "Budget";
  };
  public static revised = (): string => {
    return Localizer.translations["revised"] ?? "Revised";
  };
  public static report = (): string => {
    return Localizer.translations["report"] ?? "Report";
  };
  public static description = (): string => {
    return Localizer.translations["description"] ?? "Description";
  };
  public static applicationBudget = (): string => {
    return Localizer.translations["applicationBudget"] ?? "Budget items";
  };
  public static institution = (): string => {
    return Localizer.translations["institution"] ?? "Institution";
  };

  public static sendToNormaDisabledFilters = (): string => {
    return (
      Localizer.translations["sendToNormaDisabledFilters"] ??
      "Please remove any filters before sending to Norma"
    );
  };
  public static sendToNormaDisabledBudgetConstraints = (): string => {
    return (
      Localizer.translations["sendToNormaDisabledBudgetConstraints"] ??
      "Please adjust the budget to fit the budget constraints"
    );
  };
  public static saveAndClose = (): string => {
    return Localizer.translations["saveAndClose"] ?? "Save and close";
  };
  public static difference = (): string => {
    return Localizer.translations["difference"] ?? "Difference";
  };
  public static chooseASubcategoryToAdd = (): string => {
    return (
      Localizer.translations["chooseASubcategoryToAdd"] ??
      "Choose a subcategory to add"
    );
  };
  public static addSubcategories = (): string => {
    return Localizer.translations["addSubcategories"] ?? "Add subcategories";
  };
  public static tags = (): string => {
    return Localizer.translations["tags"] ?? "Tags";
  };
  public static createNew = (): string => {
    return Localizer.translations["createNew"] ?? "create new";
  };
  public static downloadExcel = (): string => {
    return Localizer.translations["downloadExcel"] ?? "Export";
  };
  public static addTags = (): string => {
    return Localizer.translations["addTags"] ?? "Add tags";
  };
  public static required = (): string => {
    return Localizer.translations["required"] ?? "Required";
  };
  public static FTE = (): string => {
    return Localizer.translations["FTE"] ?? "FTE";
  };
  public static FTEExplainer = (): string => {
    return (
      Localizer.translations["showAllColumns"] ??
      "FTE (Full Time Equivalent) is a term used to define the number of full time employees in a company based on time involvement."
    );
  };
  public static unauthorizedError = (): string => {
    return (
      Localizer.translations["unauthorizedError"] ??
      "You are not authorized to do this. Please try and refresh the page. If this persists, please contact an administrator."
    );
  };
  public static maxAmountExceeded = (): string => {
    return (
      Localizer.translations["maxAmountExceeded"] ?? "max amount exceeded by "
    );
  };
    public static institutionNotSelectedInSalary = (): string => {
        return (
            Localizer.translations["institutionNotSelectedInSalary"] ??
            "Select an institution for every subcategory in 'Salary'"
        );
    };

    public static institutionNotSelectedInAdministration = (): string => {
        return (
            Localizer.translations["institutionNotSelectedInAdministration"] ??
            "Select an institution for every subcategory in 'Administration'"
        );
    };

    public static institutionNotSelectedInOperation = (): string => {
        return (
            Localizer.translations["institutionNotSelectedInOperation"] ??
            "Select an institution for every subcategory in 'Operation'"
        );
    };

    public static institutionNotSelectedInDissemination = (): string => {
        return (
            Localizer.translations["institutionNotSelectedInDissemination"] ??
            "Select an institution for every subcategory in 'Dissemination, training, education'"
        );
    };
  public static belowMinimumAmount = (): string => {
    return (
      Localizer.translations["belowMinimumAmount"] ?? "below minimum amount by "
    );
  };
  public static requiredCellsAreEmpty = (): string => {
    return (
      Localizer.translations["requiredCellIsEmpty"] ??
      "required cell(s) are empty"
    );
  };
  public static maxDirectAdminExpenseExceeded = (): string => {
    return (
      Localizer.translations["maxDirectAdminExpenseExceeded"] ??
      "max direct administrative expense exceeded by "
    );
  };
  public static maxIndirectAdminExpenseExceeded = (): string => {
    return (
      Localizer.translations["maxIndirectAdminExpenseExceeded"] ??
      "max indirect administrative expense exceeded by "
    );
  };
  public static isBallParkBudget = (): string => {
    return (
      Localizer.translations["isBallParkBudget"] ??
      "no ballpark subcategories are allowed"
    );
  };
  public static removed = (): string => {
    return Localizer.translations["removed"] ?? "Removed";
  };
  public static yesDelete = (): string => {
    return Localizer.translations["yesDelete"] ?? "Yes, delete";
  };
  public static areYouSure = (): string => {
    return Localizer.translations["areYouSure"] ?? "Are you sure?";
  };
  public static deleteSubcategoryBodyText = (): string => {
    return (
      Localizer.translations["deleteSubcategoryBodyText"] ??
      "Are you sure you want to delete the subcategory budget line? All values for the line will be lost."
    );
  };
  public static cancel = (): string => {
    return Localizer.translations["cancel"] ?? "Cancel";
  };
  public static new = (): string => {
    return Localizer.translations["new"] ?? "new";
  };
  public static thankYou = (): string => {
    return Localizer.translations["thankYou"] ?? "Thank you";
  };
  public static beAware = (): string => {
    return (
      Localizer.translations["beAware"] ??
      "Be aware that you must save your application, financial reporting or budget revision."
    );
  };
  public static closeWindow = (): string => {
    return (
      Localizer.translations["closeWindow"] ??
      "To do so, please close this window."
    );
  };
  public static reportForTotal = (): string => {
    return Localizer.translations["reportForTotal"] ?? "Report for total";
  };
  public static pipelineTitle = (): string => {
    return Localizer.translations["pipelineTitle"] ?? "(pipeline)";
  };
  public static changeLogTitle = (): string => {
    return Localizer.translations["changeLogTitle"] ?? "Changelog";
  };
  public static noChangeLogsMessage = (): string => {
    return (
      Localizer.translations["noChangeLogsMessage"] ??
      "There are no changelogs for this budget"
    );
  };
  public static submit = (): string => {
    return Localizer.translations["submit"] ?? "Save and close";
  };
  public static instructions = (): string => {
    return Localizer.translations["instructions"] ?? "Instructions";
  };
  public static deleteYearWarningHeader = (): string => {
    return Localizer.translations["deleteYearWarningHeader"] ?? "Warning";
  };
  public static deleteYearWarningMessage = (): string => {
    return (
      Localizer.translations["deleteYearWarningMessage"] ??
      "The budget contains years that have been removed. If you click 'Save and close', these years will be deleted permanently."
    );
  };
  public static fteAbove100Error = (): string => {
    return (
      Localizer.translations["fteAbove100Error"] ??
      "FTE values can't exceed 99,99"
    );
  };
  public static rowMultiHeight = (): string => {
    return Localizer.translations["rowMultiHeight"] ?? "Extend lines";
  };
  public static rowSingleHeight = (): string => {
    return Localizer.translations["rowSingleHeight"] ?? "Single lines";
  };
  public static columns = (): string => {
    return Localizer.translations["columns"] ?? "Columns";
  };
  public static closeModal = (): string => {
    return Localizer.translations["closeModal"] ?? "Close";
  };
  public static budgetSubmitted = (): string => {
    return (
      Localizer.translations["budgetSubmitted"] ?? "The budget has been saved"
    );
  };
  public static budgetSubmittedMessage = (): string => {
    return (
      Localizer.translations["budgetSubmittedMessage"] ??
      "Check your application in NORMA to see the updated budget."
    );
  };
  public static showBudgetsOnly = (): string => {
    return Localizer.translations["showBudgetsOnly"] ?? "Show budgets only";
  };
  public static showAllColumns = (): string => {
    return Localizer.translations["showAllColumns"] ?? "Show all columns";
  };
  public static projectSupplement = (): string => {
    return Localizer.translations["projectSupplement"] ?? "Project supplement";
  };
}

